import React from 'react'
import '../form/form.css'

const form = () => {
    return (

        // <!-- <div class="grey-line"></div> -->
        <section id="contact" class="bg-white">
            <div class="container m-b-70">
                <div class="row">
                    <div class="col-lg-8 col-md-8 m-auto float-none">
                        <h3 class="black f-s60 f-w500 text-center">Contact us</h3>
                        <p class="grey text-uppercase f-s24 f-w300 text-center">Ask more informations</p>
                        <form class="contactus m-t-40 text-left">
                            <label>Name</label>
                            <input type="text" name="name" class="form-control m-b-20" />
                            <label>Email</label>
                            <input type="text" name="email" class="form-control m-b-20" />
                            <label>Message</label>
                            <textarea class="form-control m-b-20"></textarea>
                            <button class="btn btn-primary btn-lg page-scroll f-s18 sr-button" type="submit">Send</button>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default form