// import React from 'react'
import React, { useState, useEffect } from 'react';
import '../navbar/navbar.css'
import WhiteLogo from '../../images/logo_white.png'

const Navbar = () => {

    const [isScrolled, setIsScrolled] = useState(false);

    const handleScroll = () => {
        if (window.scrollY > 100) {
            setIsScrolled(true);
        } else {
            setIsScrolled(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const [isCollapsed, setIsActive] = useState(false);

    // Function to toggle the class
    const toggleClass = () => {
        setIsActive(!isCollapsed);  // Toggle the state between true and false
    };


    return (
        // { isScrolled? 'header scrolled': 'header' }
        < nav id="mainNav" className={isScrolled ? 'navbar navbar-default navbar-fixed-top p-0 affix' : 'navbar navbar-default navbar-fixed-top p-0 affix-top'} >
            <div class="container-fluid d-block">
                <div class="navbar-header w-1l00">
                    <button type="button" className="navbar-toggle collapsed" data-toggle="collapse"
                        data-target="#bs-example-navbar-collapse-1" onClick={toggleClass}>
                        <span class="sr-only">Toggle navigation</span> Menu <i class="fa fa-bars"></i>
                    </button>
                    <a class="navbar-brand page-scroll" href="#page-top">
                        <img src={WhiteLogo} alt="SIT" class="img-responsive center-block h-100" />
                    </a>
                </div>

                <div className={isCollapsed ? 'collapse navbar-collapse in' : 'collapse navbar-collapse'} id="bs-example-navbar-collapse-1">
                    <ul class="nav navbar-nav navbar-right">
                        <li>
                            <a class="page-scroll" href="#home">Home</a>
                        </li>
                        <li>
                            <a class="page-scroll" href="#services">Services</a>
                        </li>
                        <li>
                            <a class="page-scroll" href="#features">Features</a>
                        </li>
                        <li>
                            <a class="page-scroll" href="#download">Download</a>
                        </li>
                        {/* <li>
                            <a class="page-scroll" href="#screenshot">Screenshot</a>
                        </li> */}
                        <li>
                            <a class="page-scroll" href="#contact">Contact</a>
                        </li>
                    </ul>
                </div>
                {/* <!-- /.navbar-collapse --> */}
            </div>
            {/* <!-- /.container-fluid --> */}
        </nav >


    )
}

export default Navbar