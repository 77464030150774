import React from 'react'
import '../hero/hero.css'
import Heromage from '../../images/smiling-african-american-woman-using-mobile-phone-shopping-online-reading-text-message 1.png'

const hero = () => {
    return (

        <header id='home'>
            <div class="header-content">
                <div class="col-lg-7 col-md-7">
                    <img src={Heromage} alt="Hello" class="img-responsive center-block mockup-header effect2 w-75" />
                </div>
                <div class="col-lg-5 col-md-5 header-content-inner">
                    <h1 id="homeHeading" class="effect1">Speak freely<br /> with your friends</h1>
                    <h2 class="f-w300 m-t-20 effect2">Secure messaging and calling for free</h2>
                    <a href="#" class="btn btn-primary btn-lg page-scroll f-s18 m-r-10 effect1"><i
                        class="fa fa-apple fa-lg fa-2x m-r-10" aria-hidden="true"></i>App Store</a>
                    <a href="#" class="btn btn-primary btn-lg page-scroll f-s18 effect1"><i
                        class="fa fa-play fa-lg fa-2x m-r-10" aria-hidden="true"></i>Google Play</a>
                </div>
            </div>
        </header>
        // <!-- /header -->


    )
}

export default hero