import React from 'react'
import '../meetcustomers/meetcustomers.css'
// import MYCLVC from './../../images/myclvc.png'
import VideoCallsPopup from './../../images/video-calls-popup.png'

const meetcustomers = () => {
    return (


        <section class="inbox-container bg-white">
            <div class="container text-center">
                <div class="row justify-content-center align-items-cente">
                    <div class="col-lg-5">
                        <div class="inbox-card">
                            <img src={VideoCallsPopup} alt="Hello" class="img-responsive center-block img-hei" />

                        </div>
                    </div>
                    <div class="col-lg-6 text-left mt-5 mt-lg-0">
                        <h2 class="text-left black f-s40 f-w700">Never miss a moment with voice and video calls</h2>
                        <p class="f-s16">Enjoy high-quality voice and video calls for seamless connections and collaborations. Download now for free and stay connected anytime, anywhere!</p>
                        <p class="f-s16">Enjoy clear voice and video calls, perfect for collaboration and communication.</p>
                        <p class="f-s16">Our platform offers crystal-clear voice and video calls for effortless communication.</p>

                    </div>
                </div>
            </div>
        </section>

    )
}

export default meetcustomers