import React from 'react'
import '../footer/footer.css'
import LogoPurple from './../../images/logo-purple.png'

const footer = () => {

    return (

        <footer class="bg-light-grey">
            <div class="container">
                <div class="cta-section">
                    <h2>Ready To Grow Your Business? <br /> Start with Harsafe Meets, Become Faster Every Second</h2>
                    <a href="#" class="btn-primary btn btn-lg">Start Chatting Now</a>
                </div>
                <div class="row">
                    <div class="col-lg-12 col-md-12 text-center m-t-70">
                        <div class="foter-medium">
                            <img src={LogoPurple} alt="Hello"
                                class="img-responsive center-bloc foter-logo" />
                            <ul class="f-s14 f-w700 grey">
                                <li><a class="page-scroll" href="#home">Home</a></li>
                                <li><a class="page-scroll" href="#services">Services</a></li>
                                <li><a class="page-scroll" href="#features">Features</a></li>
                                <li><a class="page-scroll" href="#download">Download</a></li>
                                {/* <li><a class="page-scroll" href="#screenshot">Screenshot</a></li> */}
                                <li><a class="page-scroll" href="#contact">Contact</a></li>
                            </ul>
                            <div class="social">
                                <a href="#" class="green"><i class="fa fa-facebook-square fa-2x m-r-20" aria-hidden="true"></i></a>
                                <a href="#" class="green"><i class="fa fa-twitter fa-2x m-r-20" aria-hidden="true"></i></a>
                                <a href="#" class="green"><i class="fa fa-linkedin-square fa-2x" aria-hidden="true"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="footer-bottom">
                    <p>&copy; Copyright 2024, All Rights Reserved</p>
                    <div class="footr-botom-link">
                        <a href="#">Privacy Policy</a> | <a href="#">Terms & Conditions</a>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default footer