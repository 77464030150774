import React from 'react'
import '../cutomerrating/cutomerrating.css'
import GDO from './../../images/gdo.png'

const cutomerrating = () => {
    return (

        <section class="inbox-container ">
            <div class="container text-center">
                <div class="row justify-content-center align-items-cente">
                    <div class="col-lg-6">
                        <div class="inbox-card">
                            <img src={GDO} alt="Hello" class="img-responsive center-block img-hei mw-400 w-100" />
                        </div>
                    </div>
                    <div class="col-lg-6 text-start mt-5 mt-lg-0">
                        <h2 class="text-left black f-s40 f-w700">Get direct orders from your customers</h2>
                        <p class="f-s16 text-left">Create custom landing pages with Rareblocks that converts more visitors than any
                            website. With lots of unique blocks easily build a page. There are many variations of passages
                            of available.</p>
                        <div class="d-flex align-items-center mt-4">
                            <div class="mr-5">
                                <span class="metrics">4.3K+</span>
                                <p class="f-s14">Website's Powering</p>
                            </div>
                            <div>
                                <span class="metrics">7M+</span>
                                <p class="f-s14">Chats in Last 2023</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


    )
}

export default cutomerrating