import React from 'react'
import '../conversations/conversations.css'
import Conversations from './../../images/conversations.png'

const conversations = () => {
    return (

        <section class="inbox-container ">
            <div class="container text-center">
                <div class="row justify-content-center align-items-cente">
                    <div class="col-lg-6 text-start mt-5 mt-lg-0 text-left">
                        <h2 class="text-left black f-s40 f-w700">Start Selling, Management, UPI, Directly Inside Conversations with Harsafe Meet.</h2>
                        <p class="text-left f-s16">Streamline transactions, engage clients, and boost sales with integrated in-chat purchasing. Simplify your sales process and enhance customer interactions—download now for free!</p>
                        <a href="#screenshot"
                            class="btn btn-primary btn-lg page-scroll f-s16 f-w700 sr-button float-left">Download Now</a>
                    </div>
                    <div class="col-lg-5">
                        <div class="inbox-card">
                            <img src={Conversations} alt="Hello" class="img-responsive center-block img-hei" />

                        </div>
                    </div>
                </div>
            </div>
        </section>



    )
}

export default conversations