import React from 'react'
import HomeChat from '../../images/home-chat.png'

const whychooseus = () => {
    return (


        <section class="bg-whit has-svg features">
            {/* <svg xmlns="http://www.w3.org/2000/svg" version="1.1" class="separator separator-top" width="100%" height="100"
            viewBox="0 0 100 101" preserveAspectRatio="none">
            <path d="M0 100 L100 0 L100 100 Z"></path>
        </svg> */}
            <div class="container">
                <div class="row">
                    <div class="col-lg-5 col-md-5">
                        <img src={HomeChat} alt="Hello"
                            class="img-responsive center-block img-hei" />
                    </div>
                    <div class="col-lg-7 col-md-7 text-right">
                        <div class="same-heading-ewallet">
                            <h2>Why <strong>Choose</strong> Our App</h2>
                            <p>Totally free. Also calls. No limits.</p>
                        </div>
                        {/* <h3 class="black f-s60 f-w500">Why choose our app</h3>
                        <h2 class="grey text-uppercase f-s36 f-w300">Totally free. Also calls. No limits.</h2> */}
                        <p class="grey f-s16 m-b-40 f-w300">Choose Harsafe Meets for its unparalleled combination of high-definition video and audio quality, robust end-to-end encryption, and seamless real-time collaboration tools. Our app offers integrated chat for efficient communication, secure recording and playback of meetings, and in-chat sales capabilities for streamlined transactions. Experience superior security, productivity, and user convenience with Harsafe Meets. <br /><br />
                            Opt for Harsafe Meets to elevate your virtual meetings with superior HD video and audio quality. Our app ensures robust end-to-end encryption for secure communications, while real-time collaboration tools and integrated chat enhance teamwork. Record meetings for future reference and enjoy streamlined transactions with in-chat sales. Compatible with all devices, Harsafe Meets offers reliability and efficiency in every interaction.
                        </p>
                        <a href="#download" class="btn btn-primary btn-lg page-scroll f-s16 f-w700 sr-button">Download our
                            app</a>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default whychooseus